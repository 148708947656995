import React, { useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { BarLoader } from 'react-spinners';
import _ from 'lodash';
import { FaListCheck } from 'react-icons/fa6';
import { FaRegFileAlt } from 'react-icons/fa';
import { PiExport } from 'react-icons/pi';

import FileDetails from '../list_details';
import ResultTable from '../result_table';
import PieChart from '../piechart';
import CircularProgress from '../circular_progress';
import DownloadModal from '../download_modal';
import DeliverabilityScoreTable from '../deliverability_table';

import * as Constants from '../../../../helpers/constants';
import { ALL_RESULT_METRICS, DELIVERABILITY_TABLE_DISPLAY_ORDER } from '../../helpers/constants';
import { prospectMetricFormatter } from '../../helpers/helper';
import * as Helpers from '../../../../helpers/helper'
import { ApiHelper } from '../../../../helpers/apihelper';

import AppConfigContext from '../../../app_layout/app_config_context'

const IndividualList = ({
  service,
  ...props
}) => {

  const appConfig = useContext(AppConfigContext)
  const client = appConfig.client
  const id = useSelector((state) => state.user.id);

  const [state, setState] = useState({
    individualList: props.individualList,
    showDownloadModal: false,
    loading: true,
    userDetails: {
      user_id: null,
      user_name: null,
      user_type: null,
      showUserDetailsModel: false
    },
    removeModal: {
      showRemoveModal: false,
      removeMessage: ''
    }
  });

  const openDownloadModal = () => {
    if (service.NAME === 'email_finder') {
      downloadEfResultFile()
    }
    else {
      setState(prevState => ({
        ...prevState,
        showDownloadModal: !prevState.showDownloadModal
      }));
    }
  }

  const closeDownloadModal = () => {
    setState(prevState => ({
      ...prevState,
      showDownloadModal: false
    }));
  }

  const downloadEfResultFile = () => {
    let apiParams = {
      list_id: state.individualList.list_id,
      user_id: state.individualList.user_id
    }
    let body = _.pick(apiParams, ApiHelper(client, 'DOWNLOAD_EF_LIST_RESULT_FILE').API_BODY)
    var resultFileDownloadWindow = window.open('/download/' + state.individualList.list_id)

    ApiHelper(client, 'DOWNLOAD_EF_LIST_RESULT_FILE').API(client, body)
      .then((res) => {
        if (res.status === Constants.SF_API.Success) {
          resultFileDownloadWindow.location = res.data.url
          resultFileDownloadWindow.focus()
          setTimeout(() => {
            resultFileDownloadWindow.close()
          }, 30000)
        }
      }).catch((err) => {
        console.log('getlists api erred', err)
      })
  }

  const onVerifyClick = () => {

  };

  const downloadInputFile = (list_id) => {
    const apiParams = {
      list_id,
      client_secret: Constants.CLIENT_SECRET
    };
    let body = _.pick(apiParams, ApiHelper(client, service.DOWNLOAD_INPUT_FILE).API_BODY)
    const inputFileDownloadWindow = window.open(`/download/${list_id}`);

    ApiHelper(client, service.DOWNLOAD_INPUT_FILE).API(client, body)
      .then((res) => {
        if (res.status === Constants.SF_API.Success) {
          inputFileDownloadWindow.location = res.data.url;
          inputFileDownloadWindow.focus();
          setTimeout(() => {
            inputFileDownloadWindow.close();
          }, 30000);
        }
      })
      .catch((err) => {
        console.error('getlists api error', err);
      });
  };

  const getResultTable = () => {
    let metrics = null;
    if (service.NAME === 'email_verifier') {
      if (state.individualList && state.individualList.last_verified_on) {
        metrics = state.individualList.verification_result;
      } else if (state.individualList && state.individualList.progress) {
        metrics = state.individualList.progress.stats;
      } else {
        metrics = state.individualList.details.analysis_data;
      }
    }
    else if (service.NAME === 'email_finder') {
      if (state.individualList && state.individualList.email_finder_result) {
        metrics = state.individualList.email_finder_result
      }
      else {
        if (state.individualList && state.individualList.progress) {
          metrics = state.individualList.progress.stats
        } else {
          metrics = state.individualList.details.analysis_data
        }
      }
    }
    else if (service.NAME === 'prospect') {
      const analysisData = _.get(state, 'individualList.details.analysis_data', null);
      if (analysisData && analysisData.emails && analysisData.phones) {
        metrics = prospectMetricFormatter(analysisData);
      }
    }
    else if (service.NAME === 'phone_number_verifier') {
      if (state.individualList && state.individualList.last_verified_on) {
        metrics = state.individualList.verification_result;
      }
      else {
        metrics = state.individualList.details.analysis_data;
      }
    }

    if (metrics === null) {
      return null
    }

    return (
      <ResultTable
        metrics={metrics}
        resultsArray={ALL_RESULT_METRICS}
        status={state.individualList.status}
        lastVerifiedOn={state.individualList.last_verified_on}
        client={client}
        service={service}
      />
    );
  };

  const getDeliverabilityScoreTable = () => {
    let metrics
    if (service.NAME === 'email_finder') {
      metrics = state.individualList && state.individualList.email_finder_result
        ? state.individualList.email_finder_result : state.individualList.details.analysis_data
    }
    else {
      metrics = state.individualList && state.individualList.last_verified_on
        ? state.individualList.verification_result
        : state.individualList.details.analysis_data;
    }

    return (
      <DeliverabilityScoreTable
        lastVerifiedOn={state.individualList.last_verified_on}
        metrics={metrics}
        resultsArray={DELIVERABILITY_TABLE_DISPLAY_ORDER}
        individualList={state.individualList}
        openDownloadModal={openDownloadModal}
        service={service}
      />
    );
  };

  const getFileUploadProgress = () => {
    const actionInProgress = service.NAME === 'prospect' ? state.individualList.enrich_action_inprogress : state.individualList.action_inprogress;
    const listStatus = state.individualList.status ? state.individualList.status : ''
    const listExpiresOn = state.individualList.non_verified_list_expires_on ? state.individualList.non_verified_list_expires_on : ''
    const listType = state.individualList.type ? state.individualList.type : ''

    if (_.isEmpty(actionInProgress)) {
      return null
    }

    if (actionInProgress.action === 'sync' || actionInProgress.action === 'analyse' ||
      actionInProgress.action === 'find_emails' && actionInProgress.status === 'queued'
    ) {
      return (
        <div className='progress-info-div'>
          <div className='file-upload-progress'>
            <p className='w-auto'>{Constants.LIST_ACTIONS[actionInProgress.action].queued}</p>
            <BarLoader
              color='#03a9f4'
              height='3'
            />
          </div>
        </div>
      );
    } else if (actionInProgress.status && actionInProgress.action !== 'export') {
      return <CircularProgress
        service={service}
        listDetails={state.individualList}
      />;
    } else {

      let btnText = Constants.BUTTON_TEXT_FOR_SERVICE_TYPES[service.NAME]
      if (listStatus === Constants.LIST_STATUS.expired || (listType === 'upload' && listExpiresOn && listExpiresOn <= 0)) {
        btnText = Helpers.capitalizeFirstLetter(Constants.LIST_STATUS.expired)
      }
      return (
        <div className='progress-info-div'>
          <Button
            variant='outline-primary'
            onClick={onVerifyClick}
            disabled
            style={{ cursor: 'not-allowed', pointerEvents: 'auto' }}
          >
            <FaListCheck
              size={16}
              className='me-2'
            />
            {btnText}
          </Button>
        </div>
      );
    }
  };

  const getPieChart = () => {
    let graphData
    if (service.NAME === 'email_finder') {
      graphData = state.individualList.email_finder_result
    }
    else if (service.NAME === 'prospect') {
      const lastEnrichedOn = _.get(state.individualList, 'last_enriched_on', null)
      return (
        <div className='chart-data-info'>
          <Button
            variant='outline-primary'
            disabled
            className='px-4'
            style={{ width: '200px' }}
          >
            <FaRegFileAlt
              size={16}
              className='me-1'
            />
            Enrich
          </Button>
          {
            !_.isEmpty(lastEnrichedOn) ? (
              <p>Enriched On :{(Helpers.formatTime(new Date(), 'YYYY') === Helpers.formatTime(lastEnrichedOn, 'YYYY'))
                ? Helpers.formatTime(lastEnrichedOn, ' DD MMM hh:mm A')
                : Helpers.formatTime(lastEnrichedOn, ' DD MMM YYYY hh:mm A')}
              </p>
            ) : null
          }
        </div>
      )
    }
    else {
      graphData = state.individualList.verification_result
    }
    return (
      <div className='chart-data-info'>
        <PieChart
          graphData={graphData}
          isGraphDataLoading={false}
          height={50}
          width={50}
          client={client}
          service={service}
        />
      </div>
    )
  }

  const getResultDownloadButton = () => {
    const displayText = state.individualList.file_download?.is_downloaded ? 'Downloaded' : 'Download Result';
    const creditsRequired = state.individualList.file_download?.credits_required || 0;

    return (
      <Button
        className='mb-1'
        style={{ width: '200px' }}
        variant='outline-primary'
        onClick={() => openDownloadModal(state.individualList.list_id)}
      >
        <i className='fa fa-download'></i>
        <span className='ms-1'>{displayText}</span>
        {creditsRequired > 0 && <p className='credits-req-span'>({creditsRequired.toLocaleString()} Credits Required)</p>}
      </Button>
    );
  };

  const getUserDetails = (user_id, user_name, user_type) => {
    setState(prevState => ({
      ...prevState,
      userDetails: {
        user_id,
        user_name,
        user_type,
        showUserDetailsModel: true
      }
    }));
  };

  const renderPieChart = () => {
    let render = false
    if ((service.NAME === 'email_verifier' || service.NAME === 'phone_number_verifier') && state.individualList.last_verified_on) {
      render = true
    }
    else if (service.NAME === 'email_finder' && state.individualList.last_processed_on) {
      render = true
    }
    else if (service.NAME === 'prospect') {
      render = !_.values(state.individualList.enrich_action_inprogress).every((v) => v)
    }
    else {
      render = false
    }
    return render
  }

  const getProspectResultExportButton = () => {
    if (service.NAME === 'prospect') {
      return (
        <Button
          className='mb-1 ps-5 pe-5'
          variant='outline-primary'
          onClick={exportProspectResultFile}
        >
          <PiExport
            size={18}
            className='me-2'
          />
          Export to CSV
        </Button>
      )
    }
  }

  const exportProspectResultFile = () => {
    let apiParams = {
      user_id: state.user_id,
      list_id: state.individualList.list_id,
      co_user_id: id,
      action_type: 'download_as_csv'
    }
    let body = _.pick(apiParams, ApiHelper(client, 'DOWNLOAD_PROSPECT_RESULT_FILE').API_BODY)
    ApiHelper(client, 'DOWNLOAD_PROSPECT_RESULT_FILE').API(client, body)
      .then((res) => {
        if (res.status === Constants.SF_API.Success) {
          if (res.data.url) {
            let downloadWindow = window.open('/download/prospect')

            downloadWindow.location = res.data.url
            downloadWindow.focus()
            setTimeout(() => {
              downloadWindow.close()
            }, 15000)
          } else {
            console.log('downloadProspectList api erred')
          }
        }
      }).catch((err) => {
        console.log('downloadProspectList api erred', err)
      })
  }

  return (
    <li className='list-view'>
      <div className='list-view-container'>
        <div className='file-info'>
          <FileDetails
            createdOn={state.individualList.created_on}
            timeTaken={state.individualList.verification_result?.time_taken || state.individualList.email_finder_result?.time_taken}
            listId={state.individualList.list_id}
            mode={state.individualList.mode}
            lastVerifiedOn={state.individualList.last_verified_on || state.individualList.last_processed_on}
            lastCancelledOn={state.individualList.last_cancelled_on}
            accountDetails={state.individualList.details}
            listName={state.individualList.details.name || state.individualList.list_name}
            fileType={state.individualList.type}
            userDetails={state.individualList.user_details}
            downloadInputFile={downloadInputFile}
            getUserDetails={getUserDetails}
            verifiedOn={state.individualList.verification_started_on}
            action_inprogress={state.individualList.action_inprogress?.status}
            progressUpdatedOn={state.individualList.progress?.updated_on}
            resultExpiresOn={state.individualList.result_expires_on}
            listComponent='email-verifier'
            showUserDetailsModel={state.userDetails.showUserDetailsModel}
            service={service}
            {...props}
          />
          {getDeliverabilityScoreTable()}
          <div className='d-flex flex-column align-items-center mt-1 w-100'>
            {state.individualList.file_download ?
              state.individualList.result_expires_on > 0 ?
                getResultDownloadButton()
                :
                <Button
                  className='btn-clearout-export btn-outline-primary'
                  disabled
                >
                  Result Expired
                </Button>
              : null
            }
            {state.individualList.file_download && state.individualList.result_expires_on > 0 && (
              <p className='file-uploaded-date file-expire-date'>
                Result Expires in {Helpers.getResultExpiresOnTime(state.individualList.result_expires_on)}
              </p>
            )}
            {
              getProspectResultExportButton()
            }
          </div>
        </div>
        {getResultTable()}
        {renderPieChart() ? getPieChart() : getFileUploadProgress()}
      </div>
      {
        state.showDownloadModal ? (
          <DownloadModal
            showDownloadModal={state.showDownloadModal}
            user_id={state.user_id}
            listDetails={state.individualList}
            closeDownloadModal={closeDownloadModal}
            {...props}
          />
        ) : null
      }
    </li>
  );
};

export default IndividualList;
