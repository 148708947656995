import React, { useRef, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const ClickToCopy = ({ text }) => {
  const textRef = useRef(null);
  const [tooltipText, setTooltipText] = useState('Copy');

  const copyToClipboard = () => {
    const textToCopy = textRef.current.innerText;
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        // Show copied message
        setTooltipText('Copied!');
        // Hide copied message after 1 second
        setTimeout(() => {
          setTooltipText('Copy');
        }, 2000);
      })
      .catch((error) => {
        console.error('Error copying text: ', error);
      });
  };

  return (
    <OverlayTrigger
      trigger={['hover', 'click']}
      placement="top"
      overlay={
        <Tooltip
          id="tooltipText"
          className='position-fixed'
        >
          {tooltipText}
        </Tooltip>
      }
    >
      <p
        ref={textRef}
        className="cursor-pointer m-0"
        onClick={copyToClipboard}
        data-toggle="popover"
        data-trigger="focus"
        tabIndex="0"
      >
        {text}
      </p>
    </OverlayTrigger>
  );
};

export default ClickToCopy;
