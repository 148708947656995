import React from 'react';

import CurrencyDropdown from '../dropdowns/currency_dropdown';
import ProductListDropdown from '../dropdowns/product_list';
import CouponCodeDropdown from '../dropdowns/coupon_list';
import BillingTypeDropdown from '../dropdowns/billing_type_dropdown';

import QuantityInput from '../inputs/quantity_input';


const GenerateURLForm = ({ onStateChange, productOptions, couponOptions, product_id, coupon_code, quantity, selected_product }) => {


  return (
    <div className="product-create-form">
      <div className="product-intervals">
        <div className="interval-unit">
          <BillingTypeDropdown onStateChange={onStateChange} />
        </div>
        <div className="interval-count">
          <CurrencyDropdown onStateChange={onStateChange} />
        </div>
      </div>
      <ProductListDropdown
        productOptions={productOptions}
        onStateChange={onStateChange}
        product_id={product_id}
      />
      <QuantityInput
        selected_product={selected_product} 
        onStateChange={onStateChange}
        quantity={quantity}
      />
      {product_id && (
        <CouponCodeDropdown
          selected_product={selected_product} 
          onStateChange={onStateChange}
          couponOptions={couponOptions}
          coupon_code={coupon_code}
        />
      )}

    </div>
  );
};

export default GenerateURLForm;
