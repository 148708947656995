import ApiService from '../apiservice'

export default {
  USER_LIST: {
    API: ApiService.getUserList,
    API_BODY: ['skip', 'limit', 'search', 'sort', 'filters']
  },
  INSIGHT_GRAPH: {
    API: ApiService.clearoutGraphInsights,
    API_BODY: ['co_user_id', 'co_org_id', 'filters']
  },
  USER_ACTIVITY: {
    API: ApiService.getClearoutUsersActivities,
    API_BODY: ['co_user_id', 'skip', 'limit', 'search', 'sort', 'filters']
  },
  CREATE_USER: {
    API: ApiService.createClearoutUser,
    API_BODY: ['email', 'phone']
  },
  ORGANIZATION_NAMES: {
    API: ApiService.getOrganizationNames,
    API_BODY: ['limit', 'skip', 'sort']
  },
  USER_INFO: {
    API: ApiService.getCleaoutUserInfo,
    API_BODY: ['co_user_id']
  },
  EDIT_EMAIL: {
    API: ApiService.clearoutUpdateUserEmail,
    API_BODY: ['co_user_id', 'user_id', 'email']
  },
  UPDATE_TIMEZONE: {
    API: ApiService.clearoutUpdateTimezone,
    API_BODY: ['co_user_id', 'timezone']
  },
  ADD_BONUS: {
    API: ApiService.giveBonusClearoutUser,
    API_BODY: ['co_user_id', 'user_id', 'credits', 'description']
  },
  RESET_BONUS: {
    API: ApiService.resetBonusClearoutUser,
    API_BODY: ['co_user_id', 'user_id', 'description']
  },
  RADAR: {
    API: ApiService.toggleRadar,
    API_BODY: ['co_user_id', 'user_id', 'reason', 'radarStatus']
  },
  UPDATE_API_RATE_LIMIT: {
    API: ApiService.updateRateLimitIndividual,
    API_BODY: ['api_rate_limits', 'co_user_id', 'reason', 'user_id']
  },
  REMOVE_API_RATE_LIMIT: {
    API: ApiService.removeRateLimitIndividual,
    API_BODY: ['api_names', 'co_user_id', 'reason', 'user_id']
  },
  ADD_DAILY_LIMIT: {
    API: ApiService.clearoutAddDailyLimit,
    API_BODY: ['co_user_id', 'user_id', 'daily_verify_limit', 'reason']
  },
  REMOVE_DAILY_LIMIT: {
    API: ApiService.clearoutUsersRemoveDailyLimit,
    API_BODY: ['co_user_id', 'affiliate', 'user_id']
  },
  UPDATE_DAILY_LIMIT: {
    API: ApiService.clearoutUpdateDailyLimit,
    API_BODY: ['co_user_id', 'user_id', 'daily_verify_limit', 'reason']
  },
  RECEIPT_LIST: {
    API: ApiService.listInvoices,
    API_BODY: ['co_user_id']
  },
  GENERATE_RECEIPT: {
    API: ApiService.clearoutMarkAsPaid,
    API_BODY: ['co_user_id', 'user_id', 'amount', 'payment_type', 'credits', 'payment_gateway', 'description']
  },
  LOGOUT_SESSIONS: {
    API: ApiService.logoutAllSessionOfClearoutUser,
    API_BODY: ['co_user_id', 'session_type']
  },
  BLOCK_USER: {
    API: ApiService.blockClearoutUser,
    API_BODY: ['co_user_id', 'user_id', 'reason']
  },
  UNBLOCK_USER: {
    API: ApiService.unblockClearoutUser,
    API_BODY: ['co_user_id', 'user_id', 'reason']
  },
  DELETE_ACCOUNT: {
    API: ApiService.deleteClearoutUser,
    API_BODY: ['co_user_id', 'reason', 'user_id']
  },
  FORGOT_PASSWORD_LINK: {
    API: ApiService.resetTokens,
    API_BODY: ['co_user_id', 'action_type']
  },
  SIGN_UP_LINK: {
    API: ApiService.resetTokens,
    API_BODY: ['co_user_id', 'action_type']
  },
  USER_LIST_DOWNLOAD: {
    API: ApiService.getClearoutUsersList,
    API_BODY: ['skip', 'limit', 'search', 'sort', 'filters', 'download']
  },
  REMOVE_EMAIL_FROM_CACHE: {
    API: ApiService.removeEmailFromCache,
    API_QUERY: ['email_address', 'user_id']
  },
  UPDATE_DISPOSABLE_DOMAINS: {
    API: ApiService.updateDisposableDomains,
    API_BODY: ['domains', 'user_id']
  },
  OVER_ALL_USAGE_REPORTS: {
    API: ApiService.overAllUsageReports,
    API_QUERY: ['filters', 'user_id']
  },
  EMAIL_VERIFY_REPORTS: {
    API: ApiService.emailVerifyReports,
    API_QUERY: ['filters', 'user_id']
  },
  EMAIL_FINDER_REPORTS: {
    API: ApiService.emailFinderReports,
    API_QUERY: ['filters', 'user_id']
  },
  PROSPECT_REPORTS: {
    API: ApiService.prospectReports,
    API_QUERY: ['filters', 'user_id']
  },
  ACCOUNT_STATUS_UPDATE: {
    API: ApiService.accountStatusUpdateClearoutUser,
    API_BODY: ['reason', 'co_user_id', 'user_id', 'status', 'co_org_id']
  },
  GET_USER_STATUS: {
    API: ApiService.getClearoutUserStatuses,
    API_BODY: ['user_id']
  },
  GET_PRODUCT_LIST: {
    API: ApiService.getCoProductList,
    API_QUERY: ['limit', 'skip', 'total_required', 'filters', 'attach_product', 'co_user_id']
  },
  GET_ADDON_PRODUCT_INFO: {
    API: ApiService.getCoAddonProductInfo,
    API_BODY: []
  },
  GET_PRODUCT: {
    API: ApiService.getProduct,
    API_QUERY: ['product_id', 'product_type', 'currency_code', 'credits', 'product_name', 'amount', 'is_custom', 'recurring', 'can_display', 'interval_count', 'interval_unit', 'interval_times', 'dropdown_options', 'credit_interval_count', 'credit_interval_unit', 'reset_credits', 'limit_interval_count', 'limit_interval_unit',]
  },
  CREATE_PRODUCT: {
    API: ApiService.createProduct,
    API_BODY: ['product_type', 'currency_code', 'credits', 'product_name', 'amount', 'is_custom', 'recurring', 'can_display', 'interval_count', 'interval_unit', 'interval_times', 'dropdown_options', 'credit_interval_count', 'credit_interval_unit', 'reset_credits', 'limit_interval_count', 'limit_interval_unit',]
  },
  UPDATE_USERS_PAYMENT_SETTINGS: {
    API: ApiService.udpateUserPaymentSettings,
    API_BODY: ['co_user_id', 'acap', 'alap', 'reason']
  },
  ATTACH_PRODUCT: {
    API: ApiService.attachProduct,
    API_BODY: ['co_user_id', 'product_id']
  },
  DETACH_PRODUCT: {
    API: ApiService.detachProduct,
    API_BODY: ['co_user_id', 'product_id']
  },
  ORG_LIST: {
    API: ApiService.getOrganizationLists,
    API_BODY: ['skip', 'limit', 'search', 'sort', 'filters']
  },
  ORG_INFO: {
    API: ApiService.getOrganizationInfo,
    API_BODY: ['co_org_id']
  },
  ORG_ADD_BONUS: {
    API: ApiService.giveBonusClearoutOrg,
    API_BODY: ['co_org_id', 'user_id', 'credits', 'description']
  },
  USER_PLANS_AND_ACTIVE_SUBSCRIPTIONS: {
    API: ApiService.getUsersPlansAndActiveSubscriptions,
    API_BODY: ['co_user_id', 'filters']
  },
  ORG_RESET_CREDITS: {
    API: ApiService.resetClearoutOrgCredits,
    API_BODY: ['co_org_id', 'user_id', 'description']
  },
  ORG_UPDATE_API_RATE_LIMIT: {
    API: ApiService.updateRateLimitOrg,
    API_BODY: ['api_rate_limits', 'co_org_id', 'reason', 'user_id']
  },
  ORG_REMOVE_API_RATE_LIMIT: {
    API: ApiService.removeRateLimitOrg,
    API_BODY: ['api_names', 'co_org_id', 'reason', 'user_id']
  },
  ORG_UPDATE_DAILY_LIMIT: {
    API: ApiService.updateOrgDailyLimit,
    API_BODY: ['co_org_id', 'user_id', 'daily_verify_limit', 'reason']
  },
  ORG_ADD_DAILY_LIMIT: {
    API: ApiService.clearoutOrgAddDailyLimit,
    API_BODY: ['co_org_id', 'user_id', 'allowed_daily_limit', 'reason']
  },
  ORG_ADD_SEATS: {
    API: ApiService.updateOrgAllocateSeat,
    API_BODY: ['co_org_id', 'user_id', 'member_seat_count', 'reason', 'host']
  },
  GET_SYSTEM_RADAR_SETTINGS: {
    API: ApiService.getSystemRadarSettings,
    API_QUERY: ['key']
  },
  UDPATE_SYSTEM_RADAR_SETTINGS: {
    API: ApiService.updateSystemRadarSettings,
    API_BODY: ['settings']
  },
  UPDATE_USERS_DEFAULT_PAYMENT_ACCOUNT: {
    API: ApiService.udpateUserDefaultPaymentAccount,
    API_BODY: ['co_user_id', 'stripe_account', 'paypal_account', 'reason']
  },
  GET_ABUSE_LIST_WRT_COUNTRY: {
    API: ApiService.getAbuseListWrtCountry,
    API_BODY: ['country']
  },
  ADD_ABUSE_ENTRY: {
    API: ApiService.addAbuseListEntry,
    API_BODY: ['type', 'value', 'reason', 'block_free_email', 'user_id', 'country_code']
  },
  SIGN_UP_ABUSE_LIMITTER: {
    API: ApiService.signUpAbuseLimiter,
    API_BODY: ['domain', 'ip', 'action', 'email', 'user_id']
  },
  GET_ABUSE_LIST: {
    API: ApiService.getAbuseList,
    API_BODY: ['skip', 'limit', 'search', 'sort', 'abuse_id']
  },
  COUPONS_LIST: {
    API: ApiService.listCoupons,
    API_BODY: ['skip', 'limit', 'search', 'sort']
  },
  CREATE_COUPONS: {
    API: ApiService.createCoupon,
    API_BODY: ['name', 'coupon', 'expiry', 'status', 'user_id', 'description', 'affiliated_to', 'coupon_type', 'allowed_redeem_count',
      'discount_type', 'discount_min', 'discount_max', 'discount_value', 'credits', 'daily_verify_limit']
  },
  UPDATE_COUPONS: {
    API: ApiService.editCoupon,
    API_BODY: ['name', 'coupon', 'expiry', 'status', 'user_id', 'description', 'affiliated_to', 'coupon_type', 'allowed_redeem_count',
      'discount_type', 'discount_min', 'discount_max', 'discount_value', 'credits', 'daily_verify_limit']
  },
  UPDATE_ABUSE_ENTRY: {
    API: ApiService.updateAbuseListEntry,
    API_BODY: ['type', 'value', 'reason', 'block_free_email', 'abuse_id', 'exempted', 'user_id', 'country_code']
  },
  GET_ABUSE_LOGS: {
    API: ApiService.getClearoutAbuseActivityLogs,
    API_BODY: ['skip', 'limit', 'search', 'sort', 'filters']
  },
  GET_ABUSE_UPDATE_ACTIVITY: {
    API: ApiService.getClearoutAbuseUpdateLogs,
    API_BODY: ['skip', 'limit', 'search', 'sort', 'filters']
  },
  USER_SUBSCRIPTIONS: {
    API: ApiService.getUserSubscriptions,
    API_BODY: ['co_user_id']
  },
  ADDITIONAL_INFO: {
    API: ApiService.getClearoutUserProfileAdditionalInfo,
    API_BODY: ['co_user_id']
  },
  EV_LIST: {
    API: ApiService.getClearoutEVLists,
    API_BODY: ['skip', 'limit', 'search', 'filters', 'sort']
  },
  MEMBERS_LIST: {
    API: ApiService.getClearoutOrgUsersList,
    API_BODY: ['skip', 'limit', 'search', 'sort', 'filters']
  },
  DOWNLOAD_RESULT_FILE: {
    API: ApiService.downloadresultForEVList,
    API_BODY: ['co_user_id', 'list_id', 'options', 'user_id']
  },
  DOWNLOAD_EV_LIST_INPUT_FILE: {
    API: ApiService.downloadEVListInputFile,
    API_BODY: ['list_id']
  },
  PROSPECT_REQUESTS: {
    API: ApiService.getClearoutProspectRequests,
    API_BODY: ['filters']
  },
  EF_LIST: {
    API: ApiService.getEmailFinderLists,
    API_BODY: ['skip', 'limit', 'search', 'filters', 'sort']
  },
  DOWNLOAD_EF_LIST_INPUT_FILE: {
    API: ApiService.downloadEmailFinderInputFile,
    API_BODY: ['list_id']
  },
  DOWNLOAD_EF_LIST_RESULT_FILE: {
    API: ApiService.downloadEmailFinderResultFile,
    API_BODY: ['list_id', 'user_id']
  },
  PROSPECT_LIST: {
    API: ApiService.getProspectLists,
    API_BODY: ['skip', 'limit', 'search', 'filters', 'sort']
  },
  DOWNLOAD_PROSPECT_RESULT_FILE: {
    API: ApiService.downloadProspectResultFile,
    API_BODY: ['user_id', 'list_id','co_user_id',  'action_type']
  },
  GET_PRODUCT_DROP_DOWN_LIST: {
    API: ApiService.getCoProductDropDownList,
    API_QUERY: ['filters']
  }







































}