import React from 'react'
import { isMobile } from 'react-device-detect'

import { CompactTable } from '@table-library/react-table-library/compact'
import { usePagination } from '@table-library/react-table-library/pagination'
import { useTheme } from '@table-library/react-table-library/theme'
import { getTheme } from '@table-library/react-table-library/baseline'

const ProductListTable = ({ data = [], columns = [] }) => {

  const tableData = {
    nodes: data,
    id: Math.random()
  }

  function onPaginationChange(action, state) {
    console.log(action, state)
  }

  const tableTheme = useTheme(
    [
      getTheme(),
      {
        Table: `
        --data-table-library_grid-template-columns: 
          minmax(16rem, 1fr) // displayName
          minmax(7rem, 1fr) // productType
          minmax(9.5rem, 1fr) // Renewal
          minmax(10rem, 1fr) // Price
          minmax(5.5rem, 1fr) // Currency
          minmax(4.5rem, 1fr) // Custom
          minmax(12rem, 1fr) // Custom users
          // minmax(4.5rem, 1fr) // Version
          minmax(12rem, 1fr); // Created
          grid-template-rows: max-content
        `,
        HeaderCell: `
        height: fit-content;
        border-top: 1px solid #dee2e6;
        border-left: 1px solid #dee2e6;
      `,
        Row: `
          font-size:14px;
          .td {
          border-bottom: 1px solid #dee2e6;
          border-left: 1px solid #dee2e6;
        }
        `,
        BaseCell: `
        .td {
          border-left: 1px solid #dee2e6;
        }
      `,
      }
    ]
  )

  const pagination = usePagination(data, {
    state: {
      page: 0,
      size: 10,
    },
    onChange: onPaginationChange,
  })

  return (
    <div className='product-list-table'>
      <CompactTable
        columns={columns}
        data={tableData}
        pagination={pagination}
        layout={{ fixedHeader: true, horizontalScroll: !isMobile, custom: true }}
        theme={tableTheme}
      />
      <div className='d-flex justify-content-between'>
        <span>
          Total Pages: {pagination.state.getTotalPages(tableData.nodes)}
        </span>
        <span>
          Page:{' '}
          {pagination.state.getPages(tableData.nodes).map((_, index) => (
            <button
              key={index}
              type='button'
              style={{
                fontWeight:
                  pagination.state.page === index
                    ? 'bold'
                    : 'normal',
              }}
              onClick={() => pagination.fns.onSetPage(index)}
            >
              {index + 1}
            </button>
          ))}
        </span>
      </div>
    </div>
  )
}

export default ProductListTable