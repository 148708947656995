import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import * as Constants from './../../../../helpers/constants'
import showToastNotification from './../../../widgets/toastify';
import { ApiHelper } from '../../../../helpers/apihelper';

const initialState = {
  reason: 'User Requested',
  errorMessage: '',
  isApiCallInProgress: false
};

function UnBlockUserModel({ userData, getUserInfo, client, show, onHide }) {
  const [state, setState] = useState(initialState);
  const id = useSelector((state) => state.user.id);
  const userName = useSelector(state => state.user.name);

  const handleReasonChange = (e) => {
    setState(prevState => ({ ...prevState, reason: e.target.value }))
  }

  const handleSaveChanges = () => {
    if (state.isApiCallInProgress) {
      return;
    }
    let requestBody = {
      co_user_id: userData.user_id,
      cop_user_id: userData.user_id,
      user_id: id,
      reason: state.reason,
      client_secret: Constants.CLIENT_SECRET,
      crm_user_data: {
        name: userName,
        user_id: id,
      },
    };
    let body = _.pick(requestBody, ApiHelper(client, 'UNBLOCK_USER').API_BODY)
    setState(prevState => ({ ...prevState, isApiCallInProgress: true }));

    ApiHelper(client, 'UNBLOCK_USER').API(client, body)
      .then((response) => {
        if (response.status === Constants.CO_API.FAILED) {
          setState(prevState => ({ ...prevState, errorMessage: response.message }));
          showToastNotification({
            message: 'Failed to Unblock user',
            isSuccess: false,
          });
          setTimeout(() => {
            setState(prevState => ({ ...prevState, errorMessage: '' }));
          }, 5000);
        } else if (response.status === Constants.CO_API.SUCCESS) {
          showToastNotification({
            message: 'Successfully Unblocked user',
            isSuccess: true,
          });
          getUserInfo()
          handleCloseModal()
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setState(prevState => ({ ...prevState, isApiCallInProgress: false }));
      });
  };

  const handleCloseModal = () => {
    setState(initialState);
    onHide();
  };
  return (
    <>
      <Modal
        show={show}
        onHide={handleCloseModal}
      >
        <Modal.Header closeButton>
          <Modal.Title className='fs-6 fw-bold'>
            Are You Sure want to Unblock {userData.name || userData.email}&apos;s account
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body-bg-color'>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className='row'>
              <Form.Group
                className='d-flex'
              >
                <div className='col-md-2'>
                  <Form.Label className='w-100 py-2'>Reason: </Form.Label>
                </div>
                <div className='col-md-10'>
                  <Form.Control
                    type='text'
                    value={state.reason}
                    maxLength={50}
                    onChange={handleReasonChange}
                  />
                  {state.errorMessage ? <p className="text-danger" >{state.errorMessage}</p> : null}
                </div>
              </Form.Group>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='success'
            onClick={handleSaveChanges}
            disabled={state.isApiCallInProgress}
          >
            Yes!!! Unblock Account
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UnBlockUserModel;
