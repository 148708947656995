import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { CompactTable } from '@table-library/react-table-library/compact';
import { useTheme } from '@table-library/react-table-library/theme';
import { getTheme } from '@table-library/react-table-library/baseline';
import { usePagination } from '@table-library/react-table-library/pagination';
import { isMobile } from 'react-device-detect';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

import * as Constants from '../../helpers/constants';
import * as Helpers from '../../helpers/helper';

import 'react-toggle/style.css';
import '../../static/css/abuse.css';

const UserActivitiesTable = ({ getUserActivities, data }) => {
  const location = useLocation();
  const isIndividualActivityPage = location.pathname.includes('individual_user_activity');


  const initialState = {
    pageSize: 50,
    currentValue: {},
    currentPageIndex: 0,
    expandedRows: isIndividualActivityPage
      ? data?.nodes?.reduce((acc, item) => ({ ...acc, [item.id]: true }), {}) || {}
      : {},
  };

  const [state, setState] = useState(initialState);

  useEffect(() => {
    getUserActivities(state.currentPageIndex * state.pageSize, state.pageSize, null, { created_on: 'desc' });
  }, [getUserActivities, state.pageSize, state.currentPageIndex]);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      expandedRows: isIndividualActivityPage
        ? data?.nodes?.reduce((acc, item) => ({ ...acc, [item.id]: true }), {}) || {}
        : prevState.expandedRows,
    }));
  }, [data, isIndividualActivityPage]);

  const toggleExpand = (id) => {
    setState((prevState) => ({
      ...prevState,
      expandedRows: {
        ...prevState.expandedRows,
        [id]: !prevState.expandedRows[id],
      },
    }));
  };

  const tableTheme = useTheme([
    getTheme(),
    {
      Table: `
        --data-table-library_grid-template-columns: 
          minmax(15rem, 1fr) /* Action */
          minmax(30rem, 1fr) /* Action Data */
          minmax(15rem, 1fr) /* Created By */
          minmax(8rem, 1fr) /* Created on */; 
        margin: 0px;
        grid-auto-rows: minmax(max-content, max-content);
      `,
      HeaderCell: `
        height: fit-content;
        font-weight: 600;
        font-size: 15px;
      `,
      Row: `
        font-size: 14px;
        height: max-content;
      `,
    },
  ]);

  const COLUMNS = [
    { label: 'Action', renderCell: (item) => Helpers.capitalizeFirstLetter(Helpers.removeUnderscore(item.action)) },
    {
      label: 'Action Data',
      renderCell: (item) => {
        const jsonData = JSON.stringify(item.data, null, 2);
        const lines = jsonData.split('\n');
        const preview =
          lines.length > 3 ? `${lines.slice(0, 2).join('\n')}\n  ...\n}` : jsonData;

        return (
          <div className='position-relative'>
            <pre className='action_data'>{state.expandedRows[item.id] ? jsonData : preview}</pre>
            {lines.length > 3 && (
              <button
                className='expand-btn position-absolute top-0 end-0'
                onClick={() => toggleExpand(item.id)}
              >
                {state.expandedRows[item.id] ? <FiChevronUp /> : <FiChevronDown />}
              </button>
            )}
          </div>
        );
      },
    },
    { label: 'Created By', renderCell: (item) => item.created_by },
    {
      label: 'Created on',
      renderCell: (item) => (
        <>
          <div>{Helpers.timeAgo(item.created_on)}</div>
          <div className='text-wrap'>{Helpers.formatDate(item.created_on, 'DD-MMM-YYYY @ h:mm:ss a')}</div>
        </>
      ),
    },
  ];

  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setState((prevState) => ({ ...prevState, pageSize: newSize, currentPageIndex: 0 }));
    getUserActivities(0, newSize, null, { created_on: 'desc' });
  };

  const onPaginationChange = (action, newState) => {
    getUserActivities(newState.page * LIMIT, LIMIT, null, { created_on: 'desc' });
    setState((prevState) => ({
      ...prevState,
      currentPageIndex: newState.page,
    }));
  };

  const LIMIT = state.pageSize;
  const pagination = usePagination(data, {
    state: { page: state.currentPageIndex, size: LIMIT },
    onChange: onPaginationChange,
  });

  return (
    <>
      <div className='table-container'>
        {data.nodes.length === 0 ? (
          <table className='table'>
            <thead className='fs-6'>
              <tr>
                {COLUMNS.map((column, index) => (
                  <th
                    className='input-text'
                    key={index}
                  >
                    {column.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  colSpan={COLUMNS.length}
                  className='text-center pt-4'
                >
                  No data found
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <CompactTable
            columns={COLUMNS}
            data={data}
            theme={tableTheme}
            layout={{ fixedHeader: false, horizontalScroll: !isMobile, custom: true }}
          />
        )}
      </div>
      <div className='d-flex justify-content-between pt-3'>
        <Form.Select
          value={state.pageSize}
          onChange={handlePageSizeChange}
          className='vertical-pagination'
        >
          {Constants.PAGE_SIZE.values.map((size) => (
            <option
              key={size}
              value={size}
            >
              {size}
            </option>
          ))}
        </Form.Select>
        <div>
          <button
            type='button'
            disabled={pagination.state.page === 0}
            onClick={() => pagination.fns.onSetPage(0)}
          >
            {'|<'}
          </button>
          <button
            type='button'
            disabled={pagination.state.page === 0}
            onClick={() => pagination.fns.onSetPage(pagination.state.page - 1)}
          >
            {'<'}
          </button>
          <button
            type='button'
            onClick={() => pagination.fns.onSetPage(pagination.state.page + 1)}
          >
            {'>'}
          </button>
        </div>
      </div>
    </>
  );
};

export default UserActivitiesTable;
