export const EXCLUDED_PRODUCT_IDS = ['241095070400180595110500'];

export const RENEWAL_UNITS = {
  month: {
    short_name: 'mo',
    full_name: 'month',
    name: 'Monthly',
    patterns: [
      { unit: 'month', count: 1 },
      { unit: 'day', count: 30 },
      { unit: 'day', count: 31 }
    ]
  },
  year: {
    short_name: 'yr',
    full_name: 'year',
    name: 'Yearly',
    patterns: [
      { unit: 'year', count: 1 },
      { unit: 'month', count: 12 }]
  },
  day: {
    short_name: 'day',
    full_name: 'day',
    name: 'Daily',
    patterns: [{ unit: 'day', count: 1 }]
  },
  week: {
    short_name: 'week',
    full_name: 'week',
    name: 'Weekly',
    patterns: [{ unit: 'week', count: 1 }]
  }
}
