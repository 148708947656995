import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Select from 'react-select'
import Toggle from 'react-toggle';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import { ApiHelper } from '../../helpers/apihelper';
import showToastNotification from '../widgets/toastify';
import * as countryList from '../../helpers/countries'
import * as Constants from '../../helpers/constants'

import 'react-toggle/style.css';

const initialState = {
  showModal: false,
  selectedType: '',
  value: '',
  countryCode: '',
  reason: '',
  blockFreeEmail: false,
  errors: {},
}
const options = [
  { value: 'domain', label: 'Domain' },
  { value: 'ip', label: 'IP' },
  { value: 'country', label: 'Country' }
]

const AddAbuseModel = ({ client, getAbuseList }) => {
  const [state, setState] = useState(initialState)
  const id = useSelector((state) => state.user.id)
  const userName = useSelector(state => state.user.name)

  const handleShowModal = () => {
    setState(prevState => ({ ...prevState, showModal: true }));
  };

  const handleCloseModal = () => setState(initialState);

  const handleTypeChange = selectedOption => {
    setState(prevState => ({
      ...prevState,
      selectedType: selectedOption.value,
      value: '',
      countryCode: ''
    }));
  };

  const onAbuseValueChange = (e) => {
    setState(prevState => ({ ...prevState, value: e.target.value }))
  }

  const onAbuseReasonChange = (e) => {
    setState(prevState => ({ ...prevState, reason: e.target.value }))
  }

  const onCountryValueChange = (selectedOption) => {
    setState(prevState => ({ ...prevState, value: selectedOption.label, countryCode: selectedOption.value }))
    let requestBody = {
      country: selectedOption.label,
      client_secret: Constants.CLIENT_SECRET,
    }

    let body = _.pick(requestBody, ApiHelper(client, 'GET_ABUSE_LIST_WRT_COUNTRY').API_BODY)
    ApiHelper(client, 'GET_ABUSE_LIST_WRT_COUNTRY').API(client, body)
      .then(res => {
        setState(prevState => ({
          ...prevState,
          blockFreeEmail: res.data[0] ? res.data[0].block_free_email : false
        }))
      })
  }

  const onBlockFreeEmailChange = () => {
    setState(prevState => ({ ...prevState, blockFreeEmail: !state.blockFreeEmail }))
  }
  const validateForm = () => {
    let err = {};
    if (state.value.trim() === '') {
      if (state.selectedType === 'domain') err['domain'] = 'Please enter the domain';
      if (state.selectedType === 'ip') err['ip'] = 'Please enter the IP';
      if (state.selectedType === 'country') err['country'] = 'Please select the country';
    }
    if (state.selectedType.trim() === '') {
      err['abuse_type'] = 'Please select abuse type';
    }
    if (state.reason.trim() === '') {
      err['reason'] = 'Please enter the reason';
    }
    if (Object.keys(err).length) {
      setState((prevState) => ({ ...prevState, errors: err }));
      setTimeout(() => {
        setState((prevState) => ({ ...prevState, errors: {} }));
      }, 2000);
      return false;
    }
    return true;
  };

  const handleSaveChanges = () => {
    if (validateForm()) {
      let requestBody = {
        type: state.selectedType,
        value: state.value,
        country_code: state.countryCode,
        reason: state.reason,
        user_id: id,
        block_free_email: state.blockFreeEmail,
        crm_user_data: {
          user_id: id,
          name: userName
        },
        client_secret: Constants.CLIENT_SECRET,
      }

      let body = _.pick(requestBody, ApiHelper(client, 'ADD_ABUSE_ENTRY').API_BODY)
      ApiHelper(client, 'ADD_ABUSE_ENTRY').API(client, body)
        .then((response) => {
          if (response.status === Constants.CO_API.FAILED) {
            showToastNotification({
              message: 'failed',
              isSuccess: false,
            });
          } else {
            (response.status === Constants.CO_API.SUCCESS)
            showToastNotification({
              message: 'Abuse list updated',
              isSuccess: true,
            });
            getAbuseList()
            setState(initialState)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  };

  const countries = countryList.Countries

  return (
    <>
      <Button
        variant="outline-primary"
        onClick={handleShowModal}
      >
        Add
      </Button>
      <Modal
        show={state.showModal}
        onHide={handleCloseModal}
      >
        <Modal.Header closeButton>
          <Modal.Title className='fs-6 fw-bold'>Add Abuse Entry</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body-bg-color'>
          <Form>
            <div className='row'>
              <Form.Group
                className='d-flex'
              >
                <div className='col-md-3'>
                  <Form.Label className='w-100 py-2'>Abuse Type:</Form.Label>
                </div>
                <div className='col-md-9'>
                  <Select
                    options={options}
                    value={options.find(option => option.value === state.selectedType)}
                    onChange={handleTypeChange}
                  />
                  {state.errors ? <p className='text-danger'>{state.errors.abuse_type}</p> : null}
                </div>
              </Form.Group>
              {state.selectedType === 'domain' ? (
                <Form.Group
                  className='d-flex'
                >
                  <div className='col-md-3'>
                    <Form.Label className='w-100 py-2'>Domain:</Form.Label>
                  </div>
                  <div className='col-md-9'>
                    <Form.Control
                      type="text"
                      placeholder='Enter Domain'
                      onChange={onAbuseValueChange}
                      value={state.value}
                    />
                    {state.errors ? <p className='text-danger'>{state.errors.domain}</p> : null}
                  </div>
                </Form.Group>
              ) : null}
              {state.selectedType === 'ip' ? (
                <Form.Group
                  className='d-flex'
                >
                  <div className='col-md-3'>
                    <Form.Label className='w-100 py-2'>IP:</Form.Label>
                  </div>
                  <div className='col-md-9'>
                    <Form.Control
                      type="text"
                      placeholder='Enter IP'
                      onChange={onAbuseValueChange}
                      value={state.value}
                    />
                    {state.errors ? <p className='text-danger'>{state.errors.ip}</p> : null}
                  </div>
                </Form.Group>
              ) : null}
              {state.selectedType === 'country' ? (
                <Form.Group
                  className='d-flex'
                >
                  <div className='col-md-3'>
                    <Form.Label className='w-100 py-2'>Country:</Form.Label>
                  </div>
                  <div className='col-md-9'>
                    <Select
                      options={countries}
                      value={options.find(option => option.label === state.value)}
                      onChange={onCountryValueChange}
                    />
                    {state.errors ? <p className='text-danger'>{state.errors.country}</p> : null}
                  </div>
                </Form.Group>
              ) : null}
              {state.selectedType === 'country' ? (
                <Form.Group
                  className='d-flex'
                >
                  <div className='col-md-3'>
                    <Form.Label className='w-100 py-2'>Block Free Email:</Form.Label>
                  </div>
                  <div className='col-md-9'>
                    <label className='pt-1'>
                      <Toggle
                        checked={state.blockFreeEmail}
                        onChange={onBlockFreeEmailChange}
                      />
                    </label>
                  </div>
                </Form.Group>
              ) : null}
              <Form.Group
                className='d-flex'
              >
                <div className='col-md-3'>
                  <Form.Label className='w-100 py-2'>Reason:</Form.Label>
                </div>
                <div className='col-md-9'>
                  <Form.Control
                    type="text"
                    placeholder='Enter reason'
                    onChange={onAbuseReasonChange}
                  />
                  {state.errors ? <p className='text-danger'>{state.errors.reason}</p> : null}
                </div>
              </Form.Group>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='outline-primary'
            onClick={handleSaveChanges}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddAbuseModel;
