import React, { useState, useContext, useEffect } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { Modal, Button, Row, Col } from 'react-bootstrap';

import * as Helper from '../../../../helpers/helper';
import * as Constants from '../../../../helpers/constants';
import { ApiHelper } from '../../../../helpers/apihelper';
import {
  DOWNLOAD_OPTIONS,
  VALIDATION_FILTERS,
  COP_DOWNLOAD_FILE_APPEND_COLUMNS,
  DELIVERABLES_GUARANTEED,
  DELIVERABLES,
  NON_DELIVERABLES_SAFE_TO_SEND,
  NON_DELIVERABLES
} from '../../helpers/constants';

import showToastNotification from '../../../widgets/toastify';

import AppConfigContext from '../../../app_layout/app_config_context';

const DownloadModal = ({
  listDetails,
  showDownloadModal,
  closeDownloadModal,
  ...props
}) => {

  const appConfig = useContext(AppConfigContext)
  const client = appConfig.client

  const initialState = {
    showModal: showDownloadModal,
    selectedDownloadOption: client === Constants.CLIENTS.CLEAROUTPHONE.NAME ? 'append_cop_std_col' : 'guaranteed_deliverable',
    showImmDownload: 'email_with_std_co_col',
    errorMessageIfNoValueFound: '',
    validationFilterState: {
      valid: true,
      invalid: true,
      catch_all: true,
      unknown: true
    },
    errors: {},
    duplicates: {
      deliverable_duplicates: true,
      non_deliverable_duplicates: true,
      deliverable_with_risk_duplicates: true,
      custom_duplicates: true,
      all_result_duplicates: true,
    },
    downloadDisabled: false,
    deliverablesLabel: 'Guaranteed Deliverables',
    labelDescription: DELIVERABLES_GUARANTEED
  }

  const [state, setState] = useState(initialState);

  useEffect(() => {
    let selectedOption = 'guaranteed_deliverable';
    let immDownloadOption = 'email_with_std_co_col';

    if (client != Constants.CLIENTS.CLEAROUTPHONE.NAME && listDetails?.details?.default_generated_file) {
      selectedOption = listDetails.details.default_generated_file;
      immDownloadOption = listDetails.details.default_generated_file;
    }
    else if (client === Constants.CLIENTS.CLEAROUTPHONE.NAME) {
      selectedOption = 'append_cop_std_col'
      immDownloadOption = 'append_cop_std_col'
    }

    setState((prevState) => ({
      ...prevState,
      immDownloadOption: immDownloadOption,
      selectedDownloadOption: selectedOption
    }));

  }, [listDetails, client]);

  const toggleModal = () => {
    setState((prevState) => ({
      ...prevState,
      showModal: !prevState.showModal
    }));
  }

  const showDownloadFailedErrMessage = (err) => {
    showToastNotification({
      message: err.error ? err.error.message : err.message,
      isSuccess: false,
    });
    toggleModal();
  };

  const onDownloadOptionSelect = (val) => {
    setState((prevState) => ({
      ...prevState,
      selectedDownloadOption: val
    }));
    getDownloadOptions(val)
  }

  const onValidationFilterOptsChange = (val) => {
    setState((prevState) => ({
      ...prevState,
      validationFilterState: {
        ...prevState.validationFilterState,
        [val]: !prevState.validationFilterState[val]
      }
    }));
  };

  const onDuplicateSelect = (val) => {
    setState((prevState) => ({
      ...prevState,
      duplicates: {
        ...prevState.duplicates,
        [val]: !prevState.duplicates[val]
      }
    }));
  }

  const validateDownloadSubmit = () => {
    let valid = true;
    let errors = {};

    const validationFilter = Object.keys(VALIDATION_FILTERS[client]).filter((element) => {
      return state.validationFilterState[element] && listDetails.verification_result[element].value > 0;
    });

    if (state.selectedDownloadOption === 'custom' && validationFilter.length < 1) {
      valid = false;
      errors['validation_filter_opts'] = 'Please select at least one status filter option';
    }

    if (!state.selectedDownloadOption) {
      valid = false;
      errors['download_selected_option'] = 'Please select at least one option to download file';
    }
    setState((prevState) => ({
      ...prevState,
      errors: errors
    }));
    return valid;
  }

  const downloadResultWithPolling = (apiParams) => {
    let downloadWin;
    let body = _.pick(apiParams, ApiHelper(client, 'DOWNLOAD_RESULT_FILE').API_BODY)

    ApiHelper(client, 'DOWNLOAD_RESULT_FILE').API(client, body)
      .then((res) => {
        if (res.status === Constants.SF_API.Success) {
          if (res.data.download_queue_id) {
            const randomId = Math.floor(Math.random() * 90000) + 10000;
            const urlWithClient = `/download_result/${randomId}?client=${client}`;
            downloadWin = window.open(urlWithClient, '_blank');
            downloadWin.location = `/download_result/${res.data.download_queue_id}?client=${client}`;
            downloadWin.focus();
            toggleModal(); // Call the toggleModal function directly
          } else if (res.data && res.data.url) {
            downloadWin = window.open('/download/result', '_blank'); // Open in a new tab
            toggleModal(); // Call the toggleModal function directly
            setTimeout(() => {
              downloadWin.location = res.data.url;
              downloadWin.focus();
            }, 1000);
            setTimeout(() => {
              downloadWin.close();
            }, 30000);
          }
        } else {
          showDownloadFailedErrMessage(res); // Call the function directly
        }
      })
      .catch((err) => {
        showDownloadFailedErrMessage(err); // Call the function directly
        console.log('list result download api erred', err);
      });
  };

  const downloadResultWithoutPolling = (apiParams) => {
    let downloadWindow;
    let body = _.pick(apiParams, ApiHelper(client, 'DOWNLOAD_RESULT_FILE').API_BODY)

    ApiHelper(client, 'DOWNLOAD_RESULT_FILE').API(client, body)
      .then((res) => {
        if (res.status === Constants.SF_API.Success) {
          if (res.data && res.data.message) {
            downloadWindow = window.open(
              '/download/result?q=true&n=' + Helper.truncateMiddle(listDetails.details.name, 14),
              '_blank'
            );
            toggleModal();
            setTimeout(() => {
              downloadWindow.focus();
            }, 1000);
            setTimeout(() => {
              downloadWindow.close();
            }, 30000);
          } else if (res.data && res.data.url) {
            downloadWindow = window.open('/download/result', '_blank');
            toggleModal();
            setTimeout(() => {
              downloadWindow.location = res.data.url;
              downloadWindow.focus();
            }, 1000);
            setTimeout(() => {
              downloadWindow.close();
            }, 30000);
          }
        } else {
          showDownloadFailedErrMessage(res);
        }
      })
      .catch((err) => {
        showDownloadFailedErrMessage(err);
        toggleModal();
        console.log('list result download api erred', err);
      });
  };

  const getDownloadOptions = (option) => {
    let safeToSendExists = false,
      validationFilter = [],
      downloadDisabled = false,
      errorMessageIfNoValueFound = '',
      email_with_risk = 0,
      labelDescription = '',
      deliverablesLabel = 'Guaranteed Deliverables';

    if (listDetails.verification_result && listDetails.verification_result.safe_to_send) {
      safeToSendExists = true;
    }

    const body = {
      list_id: listDetails.list_id,
      co_user_id: listDetails.user_details.user_id,
      user_id: props.user.id,
    };

    switch (option) {
      case 'guaranteed_deliverable':
        if (safeToSendExists) {
          body.options = {
            filters: {
              safe_to_send: 'deliverables',
            },
            name: option,
            is_immediate_download: listDetails.details && listDetails.details.is_immediate_download,
            append_result: true,
            duplicates: false,
          };
          if (listDetails.verification_result && listDetails.verification_result.safe_to_send['yes'].value < 1) {
            downloadDisabled = true;
            errorMessageIfNoValueFound = 'Please select another option, as there are no deliverable email addresses in the list';
          }
          labelDescription = DELIVERABLES_GUARANTEED;
        } else {
          body.options = {
            filters: {
              status: ['valid'],
            },
            is_immediate_download: listDetails.details && listDetails.details.is_immediate_download,
            name: option,
            append_result: true,
            duplicates: false,
          };
          if (listDetails.verification_result && listDetails.verification_result['valid'].value < 1) {
            downloadDisabled = true;
            errorMessageIfNoValueFound = 'Please select another option, as there are no valid email addresses in this list';
          }
          labelDescription = DELIVERABLES;
          deliverablesLabel = 'Deliverables';
        }
        break;

      case 'non_deliverable':
        if (safeToSendExists) {
          body.options = {
            filters: {
              safe_to_send: 'non_deliverables',
            },
            is_immediate_download: listDetails.details && listDetails.details.is_immediate_download,
            name: option,
            append_result: true,
            duplicates: false,
          };
          if (listDetails.verification_result && listDetails.verification_result.safe_to_send['no'].value < 1) {
            downloadDisabled = true;
            errorMessageIfNoValueFound = 'Please select another option, as there are no non-deliverable email addresses in the list';
          }
          labelDescription = NON_DELIVERABLES_SAFE_TO_SEND;
        } else {
          body.options = {
            filters: {
              status: ['invalid'],
            },
            is_immediate_download: listDetails.details && listDetails.details.is_immediate_download,
            name: option,
            append_result: true,
            duplicates: false,
          };
          if (listDetails.verification_result && listDetails.verification_result['invalid'].value < 1) {
            downloadDisabled = true;
            errorMessageIfNoValueFound = 'Please select another option, as there are no invalid email addresses in this list';
          }
          labelDescription = NON_DELIVERABLES;
        }
        break;

      case 'guaranteed_deliverables_with_risk':
        body.options = {
          filters: {
            safe_to_send: 'deliverables_with_risk',
          },
          is_immediate_download: listDetails.details && listDetails.details.is_immediate_download,
          name: option,
          append_result: true,
          duplicates: false,
        };
        email_with_risk = listDetails.verification_result.safe_to_send['yes'].value + listDetails.verification_result.safe_to_send['risky'].value;
        if (listDetails.verification_result && email_with_risk < 1) {
          downloadDisabled = true;
          errorMessageIfNoValueFound = 'Please select another option, as there are no deliverable with risk email addresses in this list';
        }
        labelDescription = (
          <div>
            <p className="mb-0">
              The result will include Guaranteed Deliverables (mentioned above) and the email addresses that are
              determined risky. The risk factor depends upon multiple reasons like
            </p>
            <ul style={{ paddingLeft: '15px', marginBottom: '0' }}>
              <li>Low deliverability score</li>
              <li>High volume of role-based email addresses</li>
              <li>Any temporary mail account issue</li>
              <li>Mail server configured to accept all email messages </li>
            </ul>
            <p className="mb-0">
              The email addresses of this result file are safe to use when the bounce rate is strictly below 5% or using
              your own email sending platform without relying on an external Email Service Provider (ESP).
            </p>
          </div>
        );
        break;

      case 'custom':
        validationFilter = Object.keys(VALIDATION_FILTERS[client]).filter((element) => {
          return state.validationFilterState[element] && listDetails.verification_result[element].value > 0;
        });
        body.options = {
          filters: {
            status: validationFilter,
          },
          is_immediate_download: listDetails.details && listDetails.details.is_immediate_download,
          name: option,
          append_result: true,
          duplicates: !state.duplicates.custom_duplicates,
        };
        break;

      case 'email_with_std_co_col':
        body.options = {
          is_immediate_download: listDetails.details && listDetails.details.is_immediate_download,
          name: option,
          append_result: false,
        };
        break;

      case 'custom_clearoutphone':
        validationFilter = Object.keys(VALIDATION_FILTERS[client]).filter((element) => {
          return state.validationFilterState[element] && listDetails.verification_result[element].value > 0;
        });
        body.options = {
          fields: COP_DOWNLOAD_FILE_APPEND_COLUMNS,
          status_filter_options: validationFilter,
          append_result: true,
          immediate_download: false,
          duplicates: !state.duplicates.custom_duplicates,
        };
        body.client_secret = Constants.CLIENT_SECRET;
        break;

      case 'append_cop_std_col':
        body.options = {
          append_result: false,
          immediate_download: true,
        };
        body.client_secret = Constants.CLIENT_SECRET;
        break;

      default:
        body.options = {
          is_immediate_download: listDetails.details && listDetails.details.is_immediate_download,
          name: option,
          append_result: false,
        };
        break;
    }

    setState((prevState) => ({
      ...prevState,
      errorMessageIfNoValueFound: errorMessageIfNoValueFound,
      deliverablesLabel: deliverablesLabel,
      labelDescription: labelDescription,
      downloadDisabled: downloadDisabled
    }));

    return body;
  };

  const onDownloadSubmit = () => {
    if (validateDownloadSubmit()) {
      const body = getDownloadOptions(state.selectedDownloadOption);

      if (client === Constants.CLIENTS.CLEAROUTPHONE.NAME) {
        if (body.options.append_result) {
          downloadResultWithPolling(body);
        } else {
          downloadResultWithoutPolling(body);
        }
      }
      else {
        if (listDetails.details.is_immediate_download) {
          downloadResultWithPolling(body);
        } else {
          downloadResultWithoutPolling(body);
        }
      }
    }
  }

  return (
    <Modal
      className="download-result-modal"
      size='lg'
      show={state.showModal}
      onHide={closeDownloadModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>Download Verified Result</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row style={{ borderBottom: '1px solid #f4f5f7' }}>
          <Col sm={12}>
            <p className="mb-3">
              Please choose how you wish to download the result file for list:
              <span
                className="file-name"
                title={listDetails.details.name}
              >
                <b>{Helper.truncateMiddle(listDetails.details.name, 14)}</b>
              </span>
            </p>
          </Col>
        </Row>
        <Row className="download-container">
          <Col
            sm={6}
            className="append-checkbox-div"
          >
            {DOWNLOAD_OPTIONS[client].map((option) => (
              <div
                className="radio"
                key={option.key}
              >
                <label>
                  <input
                    type="radio"
                    checked={state.selectedDownloadOption === option.key}
                    name="append"
                    className="append-checkbox"
                    value={option.key}
                    id={option.key}
                    onChange={() => onDownloadOptionSelect(option.key)}
                  />
                  {option.label}
                  {state.showImmDownload === option.key && (
                    <b className="all-result-text">{option.immediateDownloadText}</b>
                  )}
                </label>
              </div>
            ))}
          </Col>
          <Col
            sm={6}
            className="download-option-div"
          >
            {DOWNLOAD_OPTIONS[client].map((option) => (
              <div
                className={classNames(
                  'download-option-desc',
                  state.selectedDownloadOption === option.key ? 'selected-option' : ''
                )}
                key={option.key}
              >
                <h3 className="option-heading">{option.label}</h3>
                <div className="option-desc pb-1">{option.description}</div>
                <p
                  className={classNames(
                    state.errorMessageIfNoValueFound ? 'option-desc mt-2 mb-2' : 'd-none'
                  )}
                >
                  <b>{state.errorMessageIfNoValueFound}</b>
                </p>
              </div>
            ))}
            {state.selectedDownloadOption === 'custom' || state.selectedDownloadOption === 'custom_clearoutphone' ? (
              <div>
                <div className='ps-2'>
                  {Object.keys(VALIDATION_FILTERS[client]).map((column, index) => {
                    let val = VALIDATION_FILTERS[client][column].value;
                    let colValue = listDetails?.verification_result?.[column].value;
                    let total = listDetails?.verification_result?.total;
                    let isDisabled = colValue > 0 ? false : true;
                    let isChecked = !isDisabled ? state.validationFilterState[val] : false;
                    return (
                      <div
                        className="checkbox pb-2"
                        key={index}
                      >
                        <label className="append-checkbox-values">
                          <input
                            className="filter-checkbox-values"
                            type="checkbox"
                            disabled={isDisabled}
                            checked={isChecked}
                            value={val}
                            onChange={() => onValidationFilterOptsChange(val)}
                          />
                          {VALIDATION_FILTERS[client][column].name}
                          {total ? (
                            <span className="filter-val">
                              ({colValue} of {total})
                            </span>
                          ) : null}
                        </label>
                      </div>
                    );
                  })}
                </div>
                <label
                  className={classNames(
                    state.errors['validation_filter_opts'] ? 'd-block error-message' : 'd-none'
                  )}
                >
                  {state.errors['validation_filter_opts']}
                </label>
                <label className="append-checkbox-values">
                  <input
                    className="filter-checkbox-values"
                    type="checkbox"
                    checked={state.duplicates.custom_duplicates}
                    value={'custom_duplicates'}
                    disabled={state.duplicateDisabled}
                    onChange={() => onDuplicateSelect('custom_duplicates')}
                  />
                  &nbsp;Remove Duplicates
                </label>
              </div>
            ) : null
            }
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <label className={classNames(state.errors['download_selected_option'] ? 'd-block error-message' : 'd-none')}>
            {state.errors['download_selected_option']}
          </label>
        </div>
        <Button
          className="m-1"
          variant='outline-primary'
          type="submit"
          onClick={onDownloadSubmit}
          disabled={state.downloadDisabled}
        >
          <i className={'fa fa-download px-2 '}></i>
          Download Result
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DownloadModal