import React, { useState } from 'react'

import CustomDropdown from '../../../widgets/custom_dropdown'

const BILLING_TYPE_OPTIONS = [
  { label: 'One-Time', value: 'one-time' },
  { label: 'Annual', value: 'annual' },
  { label: 'Monthly', value: 'monthly' }
]

const initialStates = {
  billing_type: BILLING_TYPE_OPTIONS.find(v => v.value === 'one-time')
}

const BillingTypeDropdown = ({ onStateChange }) => {

  const [state, setState] = useState(initialStates)

  const onBillingTypeChange = (selectedOption) => {
    setState((prevState) => ({
      ...prevState,
      billing_type: selectedOption
    }))

    onStateChange('billing_type', selectedOption.value)
  }

  return (
    <div className="currency-dropdown">
      <label>Select Billing Type</label>
      <CustomDropdown
        options={BILLING_TYPE_OPTIONS}
        placeholder={'Select Billing Type'}
        value={state.billing_type}
        onSelectOption={onBillingTypeChange}
        searchable={false}
      />
    </div>
  )
}

export default BillingTypeDropdown