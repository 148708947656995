import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Button } from 'react-bootstrap';
import * as Constants from '../../helpers/constants';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { faChartColumn } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CustomOverlayTrigger from '../widgets/overlay_trigger'
import _ from 'lodash'
import { ApiHelper } from '../../helpers/apihelper'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const initialData = {
  labels: [],
  datasets: [],
};

const initialstate = {
  chartData: initialData,
  usagetext: '',
  showStatsModal: false
}

const UserStatsModal = ({
  cell,
  selectedUser,
  client
}) => {

  const [state, setState] = useState(initialstate)

  const performanceStats = selectedUser.performance_stats || {
    catch_all: 0,
    duplicate: 0,
    invalid: 0,
    total: 0,
    unknown: 0,
    valid: 0,
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        // text: 'Chart.js Line Chart',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Count',
        },
      },
    },
  };

  const fetchUserData = useCallback(async (selectedDateRange) => {
    let dateFilter;
    if (selectedDateRange.datePreset) {
      dateFilter = selectedDateRange.datePreset;
    }

    let requestBody = {
      co_user_id: selectedUser._id,
      co_org_id: selectedUser.id,
      filters: { date_range: dateFilter }
    };

    let body = _.pick(requestBody, ApiHelper(client, 'INSIGHT_GRAPH').API_BODY)

    ApiHelper(client, 'INSIGHT_GRAPH').API(client, body)
      .then((res) => {
        if (res.status === Constants.CO_API.SUCCESS && res.data) {
          const data = {
            labels: res.data.labels.map((label) => label.split('T')[0]),
            datasets: res.data.values.map((value) => ({
              label: value.label,
              data: value.data,
              borderColor: value.borderColor,
              backgroundColor: value.backgroundColor,
              fill: true
            }))
          };
          setState(prevState => ({ ...prevState, chartData: data }))
        } else if (res.status === Constants.CO_API.FAILED) {
          console.log('Failed to fetch graph data');
        }
      })
      .catch((error) => {
        console.error('API request failed with an error:', error);
      });
  }, [selectedUser._id, selectedUser.id, client]);

  const onPresetClick = useCallback((preset) => {
    let selectedDateRange = {
      datePreset: preset,
      fromDate: null,
      toDate: null,
      dateLabel: 'Last 7 Days (Including Today)'
    };

    fetchUserData(selectedDateRange);
  }, [fetchUserData]);

  useEffect(() => {
    // Fetch "This Year" data when the component is initially loaded
    if (state.showStatsModal) { // Only fetch data if the modal is open
      onPresetClick('ps_last_7_days_including_today');
      setState(prevState => ({ ...prevState, usagetext: 'Last 7 days Including Today ' }))
    }
  }, [state.showStatsModal, onPresetClick]);


  const handleDateButtonClick = (preset, text) => {
    onPresetClick(preset);
    setState(prevState => ({ ...prevState, usagetext: text }))
  };

  const openUserStatsModal = () => {
    setState(prevState => ({ ...prevState, showStatsModal: true }))
  }

  if (!state.showStatsModal) {
    return (
      <CustomOverlayTrigger
        content={`click here to view the stats of ${cell.getValue()|| 'the User'}`}
        placement="bottom"
      >
        <FontAwesomeIcon
          icon={faChartColumn}
          onClick={openUserStatsModal}
          className="co-name-cell-link"
        />
      </CustomOverlayTrigger>
    )
  }

  return (
    <Modal
      show={state.showStatsModal}
      onHide={() => setState(prevState => ({ ...prevState, showStatsModal: false }))}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title className="fs-5">{selectedUser.name}&apos;s stats</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="bg-light pb-3">
          <h5>Usage stats</h5>
          <div className="d-flex justify-content-around">
            <div>
              <h6>Total: {performanceStats.total}</h6>
              <h6>Valid: {performanceStats.valid}</h6>
              <h6>Invalid: {performanceStats.invalid}</h6>
            </div>
            <div>
              <h6>Catch all: {performanceStats.catch_all}</h6>
              <h6>Unknown: {performanceStats.unknown}</h6>
              <h6>Duplicate: {performanceStats.duplicate}</h6>
            </div>
          </div>
        </div>
        <h5 className="pt-3">Usage: {state.usagetext}</h5>
        {state.chartData.datasets.some(dataset => dataset.data.some(value => value !== 0)) ? (
          <Line
            options={options}
            data={state.chartData}
          />
        ) : (
          <p className="text-center py-5">No validation Activity found</p>
        )}
        <div className="d-flex justify-content-between py-3">
          <Button
            size="sm"
            variant="primary"
            onClick={() => handleDateButtonClick('ps_yesterday', 'Yesterday')}
          >
            Yesterday
          </Button>
          <Button
            size="sm"
            variant="primary"
            onClick={() => handleDateButtonClick('ps_this_year', 'This Year')}
          >
            This Year
          </Button>
          <Button
            size="sm"
            variant="primary"
            onClick={() => handleDateButtonClick('ps_today', 'Today')}
          >
            Today
          </Button>
          <Button
            size="sm"
            variant="primary"
            onClick={() => handleDateButtonClick('ps_last_7_days_including_today', 'Last 7 days Including Today')}
          >
            Last 7 days
          </Button>
          <Button
            size="sm"
            variant="primary"
            onClick={() => handleDateButtonClick('ps_this_week_mon_today', 'This Week')}
          >
            This Week
          </Button>
          <Button
            size="sm"
            variant="primary"
            onClick={() => handleDateButtonClick('ps_last_month', 'Last Month')}
          >
            Last Month
          </Button>
          <Button
            size="sm"
            variant="primary"
            onClick={() => handleDateButtonClick('ps_this_month', 'This Month')}
          >
            This Month
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UserStatsModal;
