import React from 'react'
import { Breadcrumb, BreadcrumbItem } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Breadcrumbs = ({ options = [] }) => {
  if (!options.length)
    return null

  return (
    <Breadcrumb 
      className="fs-6 fs-md-7 fs-sm-7 fs-xs-7"
      style={{marginLeft:'10px'}}
    >
      {
        options.map(
          ({ label, href, active, title, target }, index) => (
            <BreadcrumbItem
              key={index}
              href={href}
              active={active}
              title={title}
              target={target}
              linkAs={linkAsComp}
            >
              {label}
            </BreadcrumbItem>
          ))
      }
    </Breadcrumb >
  )
}

export default Breadcrumbs

const linkAsComp = (props) => {
  const { children, href, title, target } = props
  return (
    <Link
      className="text-decoration-none"
      to={href}
      title={title}
      target={target}
    >
      {children}
    </Link>
  )
}