import React, { useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import _ from 'lodash'
import Toggle from 'react-toggle'
import { useSelector } from 'react-redux'

import showToastNotification from './../../../../widgets/toastify'
import * as Constants from './../../../../../helpers/constants'
import { ApiHelper } from '../../../../../helpers/apihelper'

const initialState = {
  acap: false,
  alap: false,
  reason: '',
  errors: {}
}

const PaymentSettingsModal = ({
  showModal,
  closeModal,
  getUserInfo,
  userData,
  client
}) => {
  initialState.acap = userData.payment.s.acap
  initialState.alap = userData.payment.s.alap

  const [state, setState] = useState(initialState)
  const crmUser = useSelector(state=>state.user);

  const validateForm = () => {
    let err = {}
    if (state.reason.trim() === '') {
      err.reason = 'Please enter the reason'
    }
    if (Object.keys(err).length) {
      setState((prevState) => ({ ...prevState, errors: err }))
      setTimeout(() => {
        setState((prevState) => ({ ...prevState, errors: {} }))
      }, 5000);
      return false
    }
    return true
  }

  const updateUsersPaymentSettings = () => {
    if (validateForm()) {
      let requestBody = {
        co_user_id: userData.user_id,
        cop_user_id: userData.user_id,
        acap: state.acap,
        alap: state.alap,
        reason: state.reason,
        crm_user_details: JSON.stringify({name: crmUser.name, user_id: crmUser.id}),
        client_secret: Constants.CLIENT_SECRET,
      }

      let body = _.pick(requestBody, ApiHelper(client, 'UPDATE_USERS_PAYMENT_SETTINGS').API_BODY)
      setState(prevState => ({ ...prevState, isApiCallInProgress: true }))
      ApiHelper(client, 'UPDATE_USERS_PAYMENT_SETTINGS').API(client, body)
        .then((response) => {
          if (response.status === Constants.CO_API.FAILED) {
            showToastNotification({
              message: response.message || 'Failed to update settings',
              isSuccess: false,
            })
          } else {
            getUserInfo()
            handleCloseModal()
            showToastNotification({
              message: response.message || 'Payment settings updated',
              isSuccess: true,
            })
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          setState(prevState => ({ ...prevState, isApiCallInProgress: false }))
        })
    }
  }

  const handleCloseModal = () => {
    setState(initialState)
    closeModal()
  }

  const handleToggleChange = (e) => {
    let name = e.target.name
    setState((prevState) => ({
      ...prevState,
      [name]: !prevState[name]
    }))
  }

  const handleReasonChange = (e) => {
    setState(prevState => ({ ...prevState, reason: e.target.value }))
  }

  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
    >
      <Modal.Header closeButton>
        <Modal.Title className='fs-6 fw-bold'>
          Change Payment Settings for {userData.name || userData.email}&apos;s account
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='modal-body-bg-color'>
        <div className='d-flex flex-column gap-2'>
          <div className='payment-settings-toggle acap'>
            <label className='payment-toggle-label'>
              Allow Credit Addon Purchase:&nbsp;
            </label>
            <Toggle
              className='acap-toggle'
              checked={state.acap}
              onChange={handleToggleChange}
              name='acap'
            />
          </div>
          <div className='payment-settings-toggle alap'>
            <label className='payment-toggle-label'>
              Allow Limit Addon Purchase:&nbsp;
            </label>
            <Toggle
              className='alap-toggle'
              checked={state.alap}
              onChange={handleToggleChange}
              name='alap'
            />
          </div>
          <div className='payment-settings-toggle reason'>
            <label className='payment-settings-reason'> Reason:&nbsp;</label>
            <Form.Control
              className='w-100'
              type='text'
              placeholder='Enter Reason'
              value={state.reason}
              onChange={handleReasonChange}
              maxLength={100}
            />
          </div>
          <p className='text-danger text-center'>{state.errors.reason}</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={updateUsersPaymentSettings}
          disabled={state.isApiCallInProgress}
        >
          Update Settings
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default PaymentSettingsModal