import React, { useCallback, useEffect, useState } from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'react-bootstrap'
import _ from 'lodash'
import { useSelector } from 'react-redux'

import ProductDropdown from './../../../../widgets/custom_dropdown'
import * as Constants from './../../../../../helpers/constants'
import showToastNotification from './../../../../widgets/toastify'
import AddonCard from './../../../../products/component/cards/addon_card'
import PlanCard from './../../../../products/component/cards/plan_cards'
import { ApiHelper } from './../../../../../helpers/apihelper'

const initialState = {
  prodOpts: [],
  selectedProduct: {},
  product: {}
}

const DetachProductModal = ({
  onDetachProductModalClose,
  detachProductModalOpen,
  userData,
  getUserInfo,
  client
}) => {

  const [state, setState] = useState(initialState)
  const id = useSelector((state) => state.user.id);
  const userName = useSelector((state) => state.user.name);
  

  const getProductOptions = useCallback((prodList) => {
    let opts = prodList.map(product => {
      let label = (
        <span className='d-flex justify-content-between'>
          <span>{product.display_name}</span>
          <small>{Constants.CURRENCY[product.price.currency].name}</small>
        </span>
      )
      let value = product._id

      return { label, value }
    })

    return opts
  }, [])

  const getProductList = useCallback(() => {
    let requestQueryParams = {
      limit: 1000,
      skip: 0,
      total_required: false,
      filters: JSON.stringify({
        status: 'active',
        is_custom: true,
        co_user_id: userData.user_id,
        cop_user_id: userData.user_id,
      }),
      crm_user_data: JSON.stringify({
        user_id: id,
        name: userName,
      }),
      client_secret: Constants.CLIENT_SECRET,
    }

    let queryParams = _.pick(requestQueryParams, ApiHelper(client, 'GET_PRODUCT_LIST').API_QUERY)

    ApiHelper(client, 'GET_PRODUCT_LIST').API(client, queryParams)
      .then((response) => {
        if (response && response.status === Constants.CO_API.SUCCESS) {
          let prodList = response.data.data.lists
          let prodOpts = getProductOptions(prodList)
          setState((prevState) => ({
            ...prevState,
            prodOpts,
            selectedProduct: _.first(prodOpts)
          }))
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }, [getProductOptions, userData.user_id, client, id, userName])

  useEffect(() => {
    if (detachProductModalOpen) {
      getProductList()
    }
  }, [detachProductModalOpen, getProductList])

  const getProductToView = useCallback(() => {
    let requestQueryParams = {
      product_id: state.selectedProduct.value,
      crm_user_data: JSON.stringify({
        user_id: id,
        name: userName,
      }),
      client_secret: Constants.CLIENT_SECRET,
    }

    let queryParams = _.pick(requestQueryParams, ApiHelper(client, 'GET_PRODUCT').API_QUERY)

    ApiHelper(client, 'GET_PRODUCT').API(client, queryParams)
      .then((response) => {
        if (response && response.status === Constants.CO_API.SUCCESS) {
          setState((prevState) => ({
            ...prevState,
            product: response.data.data.product,
          }))
        } else {
          let message = 'Something went wrong !!!'
          if (response.message)
            message = response.message

          showToastNotification({ message, isSuccess: false });
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }, [state.selectedProduct.value, client, id, userName])

  useEffect(() => {
    if (!_.isEmpty(state.selectedProduct)) {
      console.log({ selected: state.selectedProduct })
      getProductToView()
    }
  }, [state.selectedProduct, getProductToView])


  const onSelectedProduct = (selectedOpts) => {
    setState((prevState) => ({
      ...prevState,
      selectedProduct: selectedOpts
    }))
  }

  const getProductCard = () => {
    if (_.isEmpty(state.product))
      return null

    if (state.product.type === 'addon')
      return (
        <div className='product-card addon'>
          <AddonCard product={state.product} />
        </div>
      )

    if (state.product.type === 'plan')
      return (
        <div className='product-card plan'>
          <PlanCard product={state.product} />
        </div>
      )
  }

  const onClickDetach = () => {
    let requestBody = {
      co_user_id: userData.user_id,
      cop_user_id: userData.user_id,
      product_id: state.selectedProduct.value,
      crm_user_data: JSON.stringify({
        user_id: id,
        name: userName,
      }),
      client_secret: Constants.CLIENT_SECRET,
    }

    let body = _.pick(requestBody, ApiHelper(client, 'DETACH_PRODUCT').API_BODY)

    ApiHelper(client, 'DETACH_PRODUCT').API(client, body)
      .then((response) => {
        if (response && response.status === Constants.CO_API.SUCCESS) {
          let message = response.data.message
          showToastNotification({ message, isSuccess: true })
          onDetachProductModalClose()
          getUserInfo()
        } else {
          let message = 'Something went wrong !!!'
          if (response.message)
            message = response.message

          showToastNotification({ message, isSuccess: false })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getUserName = () => {
    let name = userData.name
    if (!name)
      name = userData.email

    return name
  }

  return (
    <Modal
      className='detach-product-modal'
      show={detachProductModalOpen}
      onHide={onDetachProductModalClose}
      size='lg'
    >
      <ModalHeader
        closeButton
      >
        <span className='fw-bold'>
          Detach Plan from {getUserName()}
        </span>
      </ModalHeader>
      <ModalBody>
        <div className='custom-product-name-dropdown'>
          <label>Select Plan</label>
          <ProductDropdown
            options={state.prodOpts}
            placeholder={'Select Custom Plan'}
            value={state.selectedProduct}
            onSelectOption={onSelectedProduct}
            searchable={false}
          />
          {getProductCard()}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={onClickDetach}
        >
          Detach
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default DetachProductModal