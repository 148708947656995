import React from 'react'

import ProductDropdown from '../../../widgets/custom_dropdown'


const PrdouctListDropdown = ({ onStateChange, productOptions, product_id }) => {
  const onProductChange = (selectedOption) => {
    const selectedProductOption = productOptions.find((option) => option.value === selectedOption.value);

    onStateChange('product_id', selectedOption.value);
    onStateChange('selected_product', selectedProductOption.product);

  }

  return (
    <div className="product-type-dropdown">
      <label>Select Product</label>
      <ProductDropdown
        options={productOptions}
        placeholder={'Select Product'}
        value={product_id ? productOptions.find(option => option.value === product_id) : null}
        onSelectOption={onProductChange}
        searchable={false}
      />
    </div>
  )
}
export default PrdouctListDropdown