import React, { useContext } from 'react'
import _ from 'lodash'
import RemoveEmailFromCache from './remove_email_from_cache'
import UpdateDisposableDomain from './update_disposable_domain'
import RadarSettings from './radar/settings'
import AppConfigContext from '../app_layout/app_config_context'
import * as Constants from './../../helpers/constants'

import '../../static/css/miscellaneous.css'

const MiscellaneousApi = () => {

  const appConfig = useContext(AppConfigContext)
  const client = appConfig.client

  const buttonToShow = Constants.MISCELLANEOUS_API_BUTTON_TO_INCLUDE[client].MISCELLANEOUS_API_BUTTON;
  
  return (
    <div>
      <h1 className='fs-3'>Miscellaneous API</h1>
      <div className='d-flex gap-2'>
        {
          _.includes(buttonToShow, 'remove_email_cache') && (
            <RemoveEmailFromCache
              client={client}
            />
          )
        }
        {
          _.includes(buttonToShow, 'update_domain') && (
            <UpdateDisposableDomain
              client={client}
            />
          ) 
        }
        {
          _.includes(buttonToShow, 'radar_settings') && (
            <RadarSettings
              client={client}
            />
          )
        }
      </div>
    </div>
  )
}

export default MiscellaneousApi 
