import React from 'react'

import CustomInput from '../../../widgets/custom_input'
import * as Constants from './../../../../helpers/constants';

const initialStates = {
  quantity: 0
}

const QuantityInput = ({ onStateChange, quantity, selected_product }) => {

  const onValueChange = (quantity) => {
    onStateChange('quantity', quantity)
  }

  const onClearButtonClick = () => {
    onValueChange(initialStates.quantity)
  }

  const showQuantity = (() => {

    if (selected_product && selected_product.type == Constants.PRODUCT_TYPE.addon) {
      return true;
    } else {
      return false;
    }

  })();

  return (
    <>
      {showQuantity && (
        <div className="product-quantity-input">
          <label>Enter Quantity</label>
          <CustomInput
            type="number"
            value={quantity}
            onValueChange={onValueChange}
            min={0}
            max={5000000}
            onClearButtonClick={onClearButtonClick}
          />
        </div>
      )}
    </>
  )
}

export default QuantityInput