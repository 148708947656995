import * as Constants from './../constants'

export const clientAppHelper = (client) => {

  switch (client) {
    case Constants.CLIENTS.CLEAROUTPHONE.NAME:
      return {
        PRICING_URL: `${Constants.CLIENTS[client.toUpperCase()].APP_URL}dashboard/pricing/`
      }

    default:
      return {
        PRICING_URL: `${Constants.CLIENTS[client.toUpperCase()].APP_URL}pricing/`
      }
  }
}
