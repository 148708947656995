import React, { useEffect, useState } from 'react'
import { Button, FloatingLabel, Form, Image } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'

import ApiService from '../../helpers/apiservice'
import * as Constants from '../../helpers/constants'
import { loginSuccess } from '../../reducers/userSlice'

import './../../static/css/login.css'
const coLogo = require('./../../static/img/co-crm_logo.png')

const initialState = {
  username: '',
  password: '',
  error: {}
}

const Login = () => {
  const [state, setState] = useState(initialState)
  const dispatch = useDispatch()

  // subscribing to all states
  useSelector((state) => localStorage.setItem(APP_NAME, JSON.stringify(state)))

  // reseting erors
  useEffect(() => {
    if (!_.isEmpty(state.error)) {
      setTimeout(() => {
        setState((prevState) => ({
          ...prevState, error: {}
        }))
      }, 5000)
    }
  }, [state.error])

  const onChange = (e) => {
    let target = e.target
    setState((prevState) => ({ ...prevState, [target.id]: target.value }))
  }

  const validateForm = () => {
    let errors = {}
    if (!state.username ||
      (state.username && !state.username.trim())) {
      errors['username'] = 'Please enter a valid username'
    }

    if (!state.password ||
      (state.password && !state.password.trim())) {
      errors['password'] = 'Please enter a valid password'
    }

    if (errors && Object.keys(errors).length) {
      setState((prevState) => ({ ...prevState, error: errors }))
      return false
    }

    return true
  }

  const onFormSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (validateForm()) {
      let body = {
        username: state.username.trim(),
        password: state.password.trim()
      }
      ApiService
        .login(body)
        .then((res) => {
          if (res && res.status === Constants.CO_API.SUCCESS) {
            dispatch(loginSuccess(res.data))
          } else {
            setState((prevState) => ({
              ...prevState, error: { message: 'Invalid Username or Password' }
            }))
          }
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }

  return (
    <div className="login-container d-flex align-items-center justify-content-center vh-100 bg-light">
      <div className="login-card shadow-lg rounded-3 p-4 bg-white">
        <div className="text-center mb-4">
          <a href="/login">
            <Image
              src={coLogo}
              alt="Clearout Logo"
              className="login-logo 
            mb-3"
            />
          </a>
          <p
            className="text-muted"
          >
            Sign in to continue to your CRM
          </p>
        </div>
        <Form
          onSubmit={onFormSubmit}
          className="d-flex flex-column gap-3"
        >
          <Form.Group>
            <FloatingLabel label='Username'>
              <Form.Control
                type='text'
                id='username'
                placeholder='username'
                value={state.username}
                required
                autoFocus
                onChange={onChange}
              />
              <Form.Text className="text-danger">
                {state.error.username}
              </Form.Text>
            </FloatingLabel>
          </Form.Group>
          <Form.Group>
            <FloatingLabel label='Password'>
              <Form.Control
                type='password'
                id='password'
                placeholder='password'
                value={state.password}
                required
                onChange={onChange}
              />
              <Form.Text className="text-danger">
                {state.error.password}
              </Form.Text>
            </FloatingLabel>
          </Form.Group>
          <Form.Text className="text-danger text-center">
            {state.error.message}
          </Form.Text>
          <Button
            type="submit"
            className="w-100 py-2 fw-bold sign-in"
          >
            Sign In
          </Button>
        </Form>
      </div>
    </div>
  )
}

export default Login