import React, { useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import _ from 'lodash'
import { useSelector } from 'react-redux'

import showToastNotification from '../../../../widgets/toastify'
import * as Constants from '../../../../../helpers/constants'
import * as IndividualUserInfoConstants from './../../../helpers/constant'
import CustomDropdown from '../../../../widgets/custom_dropdown'
import { ApiHelper } from '../../../../../helpers/apihelper'

const PaymentAccountModel = ({
  showModal,
  closeModal,
  getUserInfo,
  userData,
  client
}) => {

  const PAYMENT_ACCOUNTS = IndividualUserInfoConstants.PAYMENT_ACCOUNTS[client]

  const initialState = {
    stripe_account: _.find(PAYMENT_ACCOUNTS.STRIPE, { value: userData.payment.stripe.account_name }),
    paypal_account: _.find(PAYMENT_ACCOUNTS.PAYPAL, { value: userData.payment.paypal.account_name }),
    reason: '',
    errors: {}
  }

  const [state, setState] = useState(initialState)
  const id = useSelector((state) => state.user.id);
  const userName = useSelector((state) => state.user.name);


  const validateForm = () => {
    let err = {}
    if (state.reason.trim() === '') {
      err.reason = 'Please enter the reason'
    }
    if (Object.keys(err).length) {
      setState((prevState) => ({ ...prevState, errors: err }))
      setTimeout(() => {
        setState((prevState) => ({ ...prevState, errors: {} }))
      }, 5000);
      return false
    }
    return true
  }

  const updateUsersDefaultPaymentAccount = () => {
    if (validateForm()) {
      let requestBody = {
        co_user_id: userData.user_id,
        cop_user_id: userData.user_id,
        stripe_account: state.stripe_account.value,
        paypal_account: state.paypal_account.value,
        reason: state.reason,
        client_secret: Constants.CLIENT_SECRET,
        crm_user_data: JSON.stringify({
          user_id: id,
          name: userName,
        }),
      }

      let body = _.pick(requestBody, ApiHelper(client, 'UPDATE_USERS_DEFAULT_PAYMENT_ACCOUNT').API_BODY)
      setState(prevState => ({ ...prevState, isApiCallInProgress: true }))
      ApiHelper(client, 'UPDATE_USERS_DEFAULT_PAYMENT_ACCOUNT').API(client, body)
        .then((response) => {
          if (response.status === Constants.CO_API.FAILED) {
            showToastNotification({
              message: response.message || 'Failed to udpate account',
              isSuccess: false,
            })
          } else {
            getUserInfo()
            handleCloseModal()
            showToastNotification({
              message: response.message || 'Payment accounts updated',
              isSuccess: true,
            })
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          setState(prevState => ({ ...prevState, isApiCallInProgress: false }))
        })
    }
  }

  const handleCloseModal = () => {
    setState(initialState)
    closeModal()
  }

  const onDropdownSelect = (selectedOption, e) => {
    let { name } = e

    setState((prevState) => ({
      ...prevState,
      [name]: selectedOption
    }))
  }

  const handleReasonChange = (e) => {
    setState(prevState => ({ ...prevState, reason: e.target.value }))
  }

  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
    >
      <Modal.Header closeButton>
        <Modal.Title className='fs-6 fw-bold'>
          Change Default Payment Account for {userData.name || userData.email}&apos;s account
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='modal-body-bg-color'>
        <div className='d-flex flex-column gap-2'>
          <div className='payment-account-dropdown paypal-account'>
            <label>Select PayPal Account</label>
            <CustomDropdown
              name='paypal_account'
              options={PAYMENT_ACCOUNTS.PAYPAL}
              placeholder={'Select PayPal Account'}
              value={state.paypal_account}
              onSelectOption={onDropdownSelect}
            />
          </div>
          <div className='payment-account-dropdown stripe-account'>
            <label>Select Stripe Account</label>
            <CustomDropdown
              name='stripe_account'
              options={PAYMENT_ACCOUNTS.STRIPE}
              placeholder={'Select Stripe Account'}
              value={state.stripe_account}
              onSelectOption={onDropdownSelect}
            />
          </div>
          <div className='payment-account-dropdown reason'>
            <label> Reason</label>
            <Form.Control
              className='w-100'
              type='text'
              placeholder='Enter Reason'
              value={state.reason}
              onChange={handleReasonChange}
              maxLength={100}
            />
          </div>
          <p className='text-danger text-center'>{state.errors.reason}</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={updateUsersDefaultPaymentAccount}
          disabled={state.isApiCallInProgress}
        >
          Update Accounts
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default PaymentAccountModel